<template>
  <div class="content_">

    <div class="container-fluid_">
      <div class="row">
        <div class="col-md-12">
<!--          <button class="btn btn-sm btn-fill " v-bind:class="{'btn-warning':showBracket2, 'btn-dark':!showBracket2, }" style="margin: 5px;"  >Сітка 0-2</button>-->
<!--          <button class="btn btn-sm btn-fill " v-bind:class="{'btn-warning':showBracket4, 'btn-dark':!showBracket4, }" style="margin: 5px;"  >Сітка 2-4</button>-->
<!--          <button class="btn btn-sm btn-fill " v-bind:class="{'btn-warning':showBracket8, 'btn-dark':!showBracket8, }" style="margin: 5px;"  >Сітка 4-8</button>-->
<!--          <button class="btn btn-sm btn-fill " v-bind:class="{'btn-warning':showBracket16, 'btn-dark':!showBracket16, }" style="margin: 5px;"  >Сітка 8-16</button>-->
<!--          <button class="btn btn-sm btn-fill " v-bind:class="{'btn-warning':showBracket32, 'btn-dark':!showBracket32, }" style="margin: 5px;"  >Сітка 16-32</button>-->
<!--          <button class="btn btn-sm  btn-fill btn-danger" style="margin: 5px;" @click="getImage">Скачати</button>-->
<!--          <a class="btn btn-sm  btn-fill btn-warning" style="margin: 5px;" :href="baseUrlServer+'/tournament/print-pdf?category='+selectedDrawCategory.id" target="_blank" >Друк жеребування(PDF)</a>-->
<!--          <a class="btn btn-sm  btn-fill btn-warning" style="margin: 5px;" :href="baseUrlServer+'/tournament-result/print-pdf?category='+selectedDrawCategory.id" target="_blank" >Друк результатів (PDF)</a>-->

<!--          <a class="btn btn-sm  btn-fill btn-warning" style="margin: 5px;" :href="baseUrlServer+'/tournament-result-new/print-pdf?category='+selectedDrawCategory.id" target="_blank" >Друк результатів PDF New</a>-->
        </div>
        <div class="col-md-12">
          <button class="btn btn-sm  btn-fill btn-danger" style="margin: 5px;" :href="baseUrlServer+'/tournament/draw-reset?category='+selectedDrawCategory.id" @click="clearBracket()" >Рестарт сітки</button>

          <button class="btn btn-sm btn-fill" style="margin: 5px;"
                  v-bind:class="{'btn-dark':!sortableSheme,'btn-primary':sortableSheme, }" @click="toogleSortableSheme()">
            Ручне жеребкування
          </button>

<!--          <button class="btn btn-sm btn-fill btn-primary" style="margin: 5px;"
                  v-bind:class="{'btn-dark':!sortableSheme,'btn-primary':sortableSheme, }" @click="toogleAutoSortableSheme(1)">
            Автоматичне жеребкування (Рандомна система)
          </button>-->
          <button class="btn btn-sm btn-fill btn-primary" style="margin: 5px;"
                  v-bind:class="{'btn-dark':!sortableSheme,'btn-primary':sortableSheme, }" @click="toogleAutoSortableSheme(2)">
            Автоматичне жеребкування протокола
          </button>

          <a class="btn btn-sm  btn-fill btn-warning" style="margin: 5px;" :href="baseUrlServer+'/tournament-result/print-pdf?category='+selectedDrawCategory.id" target="_blank" >Друк результатів (PDF)</a>

        </div>
      </div>
    </div>



    <modal  name="event-update-form-modal" @before-close="resetBracketFightForm"  :adaptive=true  :minHeight=350 height="auto" :minWidth=400 width="80%" :scrollable=true>
      <div class="row new-create-update-form" style="border: 1px solid #000; margin-bottom: 10px; padding: 10px 15px; background: #fff" v-if="selectedFight.technicalNumber.length > 0 || selectedFight.technicalNumber > 0 ">
      <div class="col-md-12">
        <div class="form-group-sm">

<!--          .sportsman-->
          <label class="text-dark" style="margin: 5px;" for="selectedFightnumber">Поєдинок</label>
          <input id="selectedFightnumber" type="text" class="form-control-sm" style="width: 50px; border: 1px solid #000" autofocus v-model="selectedFight.number" @change="selectedFightChanged()">

          <span style="margin: 5px 25px; font-size: 15px; color: #000"></span>
          <label v-if="selectedFight.blue.spName.length > 1" class="text-dark" style="margin: 5px;" for="sportsman1">{{selectedFight.blue.spName}}</label>
          <input v-if="selectedFight.blue.spName.length > 1" id="sportsman1" type="text" class="form-control-sm" style="width: 50px; border: 1px solid #000" v-model="selectedFight.blue.points" @change="selectedFightChanged()">
          <span style="margin: 5px 25px; font-size: 15px; color: #000"></span>
          <label v-if="selectedFight.red.spName.length > 0"  class="text-dark" style="margin: 5px;" for="sportsman1">{{selectedFight.red.spName}}</label>
          <input v-if="selectedFight.red.spName.length > 0" id='sportsman2' type="text" class="form-control-sm" style="width: 50px; border: 1px solid #000" v-model="selectedFight.red.points" @change="selectedFightChanged()">
          <span style="margin: 5px 25px; font-size: 15px; color: #000"></span>
          <br>
          <hr>
    <label class="text-dark" style="margin: 5px;" for="decision">Перемога</label>
          <select v-model="selectedFight.decision" id="decision" type="text" class="form-control-sm" style="width: 250px; border: 1px solid #000; background: #fff" @change="selectedFightChanged()"  >
            <option  value="PTG" selected>по різниці балів (PTG)</option>
            <option value="DSQ">внаслідок дискваліфікації (DSQ)</option>
            <option value="WDP">неявка (WDP)</option>
            <option value="RSC">внаслідок зупинки поєдинка рефері (RSC)</option>
            <option value="PTF">по фінальному рахунку (PTF)</option>
            <option value="GDP">в додатковому (золотому раунді) (GDP)</option>
            <option value="SUP">по перевазі (SUP)</option>
            <option value="WDR">внаслідок зняття спортсмена (WDR)</option>
            <option value="PUN">внаслідок оголошення рефері мір покарання (PUN)</option>
            <option value="KO">перемога нокаутом (KO)</option>
            <option value="IRM">перемога внаслідок запиту тренера на відеоповтор (IRM)</option>
            <option value="DQB">перемога внаслідок дискваліфікації за неспортивну поведінку (DQB)</option>
            <option value="INJ">перемога через травму суперника (INJ)</option>
            <option value="WDP">перемога через неявку суперника (WDP)</option>
            <option value="JUD">перемога за рішенням суддів (JUD)</option>
            <option value="SD">перемога в раунді раптової смерті (SD)</option>
            <option value="TKO">перемога технічним нокаутом (TKO)</option>
            <option value="">інша причина</option>
          </select>

          <span style="margin: 5px 25px; font-size: 15px; color: #000; "></span>

          <label class="text-dark" style="margin: 5px;" for="winner">Переможець</label>
          <select v-model="selectedFight.winSpId" id="winner" type="text" class="form-control-sm" style="width: 250px; border: 1px solid #000; background: #fff" @keyup="selectedFightChanged()" @keydown="selectedFightChanged()" @change="selectedFightChanged()" >
            <option value="">-</option>
            <option v-if="selectedFight.blue.spId > 0" :data-val="selectedFight.blue.spId"  :value="selectedFight.blue.spId">{{selectedFight.blue.spName}}</option>
            <option v-if="selectedFight.red.spId > 0" :data-val="selectedFight.red.spId"  :value="selectedFight.red.spId">{{selectedFight.red.spName}}</option>
<!--            <option v-bind:key="sportsman.id+100" v-for="sportsman in sportsmans" :value="sportsman.id">{{sportsman.name}}</option>-->
          </select>


          <button style="margin-left: 10px;" class="btn btn-fill btn-warning" @click="saveData">Зберегти</button>

        </div>
      </div>
    </div>
    </modal>
    <div class="container-fluid_">
      <div class="row_">
        <div class="zoom-header">
          <button @click="zoomDraw = 1">100%</button>
          <button @click="zoomDraw = 0.75">75%</button>
          <button @click="zoomDraw = 0.50">50%</button>
          <button @click="zoomDraw = 0.30">30%</button>
        </div>
      </div>
      <div class="row_">
        <div class="col-md-12_ "  >
        <div  id="bracket-image-block" style="/*width: 800px;*/ " class="print-mode">
          <bracket-2 :key="selectedDrawCategory.id + 'bracket'" v-if="showBracket2"
                     :title.sync="selectedDrawCategory.fullname"
                     :sex.sync="selectedDrawCategory.sex"
                     :colorSheme.sync="colorSheme"
                     :drawdata.sync="selectedDrawCategory.drawdata"
                     :categoryid.sync="selectedDrawCategory.id"
                     :tournamentid.sync="selectedDrawCategory.tournament_id"
                     :sortableSheme.sync="sortableSheme"
                     :selectBracketFight.sync="selectBracketFight"
                     :destroy.sync="destroy"

                     ></bracket-2>


          <bracket-4 :key="selectedDrawCategory.id + 'bracket'" v-if="showBracket4"
                     :title.sync="selectedDrawCategory.fullname"
                     :sex.sync="selectedDrawCategory.sex"
                     :colorSheme.sync="colorSheme"
                     :drawdata.sync="selectedDrawCategory.drawdata"
                     :categoryid.sync="selectedDrawCategory.id"
                     :tournamentid.sync="selectedDrawCategory.tournament_id"
                     :sortableSheme.sync="sortableSheme"
                     :selectBracketFight.sync="selectBracketFight"
                     :destroy.sync="destroy"

                     ></bracket-4>

          <bracket-8 :key="selectedDrawCategory.id + 'bracket'" v-if="showBracket8"
                     :title.sync="selectedDrawCategory.fullname"
                     :sex.sync="selectedDrawCategory.sex"
                     :colorSheme.sync="colorSheme"
                     :drawdata.sync="selectedDrawCategory.drawdata"
                     :categoryid.sync="selectedDrawCategory.id"
                     :tournamentid.sync="selectedDrawCategory.tournament_id"
                     :sortableSheme.sync="sortableSheme"
                     :selectBracketFight.sync="selectBracketFight"
                     :destroy.sync="destroy"

                     ></bracket-8>

          <bracket-16 :key="selectedDrawCategory.id + 'bracket'" v-if="showBracket16"
                     :title.sync="selectedDrawCategory.fullname"
                      :sex.sync="selectedDrawCategory.sex"
                     :colorSheme.sync="colorSheme"
                     :drawdata.sync="selectedDrawCategory.drawdata"
                     :categoryid.sync="selectedDrawCategory.id"
                     :tournamentid.sync="selectedDrawCategory.tournament_id"
                     :sortableSheme.sync="sortableSheme"
                     :selectBracketFight.sync="selectBracketFight"
                     :destroy.sync="destroy"

                     ></bracket-16>

          <bracket-32 :key="selectedDrawCategory.id + 'bracket'" v-if="showBracket32"
                     :title.sync="selectedDrawCategory.fullname"
                      :sex.sync="selectedDrawCategory.sex"
                     :colorSheme.sync="colorSheme"
                     :drawdata.sync="selectedDrawCategory.drawdata"
                     :categoryid.sync="selectedDrawCategory.id"
                     :tournamentid.sync="selectedDrawCategory.tournament_id"
                     :sortableSheme.sync="sortableSheme"
                     :selectBracketFight.sync="selectBracketFight"
                     :destroy.sync="destroy"

                     ></bracket-32>
          <bracket-64 :key="selectedDrawCategory.id + 'bracket'" v-if="showBracket64"
                     :title.sync="selectedDrawCategory.fullname"
                      :sex.sync="selectedDrawCategory.sex"
                     :colorSheme.sync="colorSheme"
                     :drawdata.sync="selectedDrawCategory.drawdata"
                     :categoryid.sync="selectedDrawCategory.id"
                     :tournamentid.sync="selectedDrawCategory.tournament_id"
                     :sortableSheme.sync="sortableSheme"
                     :selectBracketFight.sync="selectBracketFight"
                      :zoomDraw.sync = zoomDraw
                     :destroy.sync="destroy"

                     ></bracket-64>
        </div>
<!--          <bracket-winners :bracketFigths.sync="bracketFigths"></bracket-winners>-->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {baseUrlServer} from '../../api/user';
  import html2canvas from 'html2canvas';
  import {Swappable, Plugins, DragStartEvent, DragStopEvent} from '@shopify/draggable';
  import bracketWinners from './bracket-winners.vue';
  export default {
    name: 'brackets-constructor',
    components: {
      Swappable, Plugins, DragStartEvent, DragStopEvent, 'bracket-winners':bracketWinners
    },
    props:{
      selectedDrawCategory:Object
    },
    data() {
      return {
        baseUrlServer:'',
        swappable: null,
        colorSheme: true,
        showBracket64:false,
        showBracket32:false,
        showBracket16:false,
        showBracket8:false,
        showBracket4:false,
        showBracket2:true,
        sortableSheme: false,
        sportsmans:[],
        bracketFigths:{},
        destroy:false,
        bracketEl:null,
        bracket_size:0,
        zoomDraw:1,
        selectedFight:
          {technicalNumber:'', number:'', decision:'', winSpId:'', winSpName:'',
            red:  {spId: '', spName: '', points: '', spOrg: ''},
            blue: {spId: '', spName: '', points: '', spOrg: ''}},
      }
    },

    // computed: {
    //   // a computed getter
    //   reversedMessage: function () {
    //     // `this` points to the vm instance
    //     return this.message.split('').reverse().join('')
    //   }
    // },

    watch: {


      selectedDrawCategory: function(newVal) {
        // this.$destroy();
        // this.$forceUpdate();
        this.initCategory();
      },

      //
      // selectedDrawCategory: function(newVal) {
      //   this.initCategory();
      // }
    },

    beforeMount() {
      // this.createEmptyFights();
    },
    created(){
      this.baseUrlServer = baseUrlServer;
    },
    mounted(){
      this.initCategory();
    },
    updated(){
    },

    methods:{

      initCategory(){

        this.disableToogleSortableSheme();

        if(this.selectedDrawCategory != undefined && this.selectedDrawCategory.id != undefined && this.selectedDrawCategory.sportsmans != undefined) {
          this.sportsmans = this.selectedDrawCategory.sportsmans;
        }

        this.$store.dispatch('settings/setActiveTournamentId',  this.selectedDrawCategory.tournament_id);
        this.$store.dispatch('settings/setActiveCategoryId',  this.selectedDrawCategory.id);


        let countSP = Object.keys(this.sportsmans).length;

        let count = 0;
        if(countSP < 3){
          count = 2;
        }else  if(countSP < 5){
          count = 4;
        }
        else  if(countSP < 9){
          count = 8;
        }
        else  if(countSP < 17){
          count = 16;
        }
        else  if(countSP < 33){
          count = 32;
        }
        else  if(countSP < 65){
          count = 64;
        }

        this.toogleBracket(count);
      },

      resetBracketFightForm(){
        // Reset данных

        this.selectedFight.blue.spId = '';
        this.selectedFight.blue.spName = '';
        this.selectedFight.blue.points = '';
        this.selectedFight.red.spId = '';
        this.selectedFight.red.spName = '';
        this.selectedFight.red.points = '';
        this.selectedFight.number = '';
        this.selectedFight.decision = '';

      },
      // выбор поединка/выступления
      selectBracketFight(fight){
        console.log('выбор поединка/выступления selectBracketFight', fight);
        this.selectedFight.technicalNumber = fight;

        this.resetBracketFightForm();
        let sportsmanRed = {};
        let sportsmanBlue = {};
        let nextFight = {};

        // в зависимости от технического номера поединка находинм соотвествующих спортсменов
        fight = parseInt(fight);
        switch (fight) {
          case 0:
            sportsmanBlue = this.getSportsmanByPosition('1_0');
            sportsmanRed = this.getSportsmanByPosition('1_1');
            nextFight = this.getSportsmanByPosition('0_0');
            break;
          case 1:
            sportsmanBlue = this.getSportsmanByPosition('2_0');
            sportsmanRed = this.getSportsmanByPosition('2_1');
            nextFight = this.getSportsmanByPosition('1_0');
            break;
          case 2:
            sportsmanBlue = this.getSportsmanByPosition('2_2');
            sportsmanRed = this.getSportsmanByPosition('2_3');
            nextFight = this.getSportsmanByPosition('1_1');
            break;

          case 3:
            sportsmanBlue = this.getSportsmanByPosition('3_0');
            sportsmanRed = this.getSportsmanByPosition('3_1');
            nextFight = this.getSportsmanByPosition('2_0');
            break;
          case 4:
            sportsmanBlue = this.getSportsmanByPosition('3_2');
            sportsmanRed = this.getSportsmanByPosition('3_3');
            nextFight = this.getSportsmanByPosition('2_1');
            break;

          case 5:
            sportsmanBlue = this.getSportsmanByPosition('3_4');
            sportsmanRed = this.getSportsmanByPosition('3_5');
            nextFight = this.getSportsmanByPosition('2_2');
            break;

          case 6:
            sportsmanBlue = this.getSportsmanByPosition('3_6');
            sportsmanRed = this.getSportsmanByPosition('3_7');
            nextFight = this.getSportsmanByPosition('2_3');
            break;

          case 7:
            sportsmanBlue = this.getSportsmanByPosition('4_0');
            sportsmanRed = this.getSportsmanByPosition('4_1');
            nextFight = this.getSportsmanByPosition('3_0');
            break;
          case 8:
            sportsmanBlue = this.getSportsmanByPosition('4_2');
            sportsmanRed = this.getSportsmanByPosition('4_3');
            nextFight = this.getSportsmanByPosition('3_1');
            break;
          case 9:
            sportsmanBlue = this.getSportsmanByPosition('4_4');
            sportsmanRed = this.getSportsmanByPosition('4_5');
            nextFight = this.getSportsmanByPosition('3_2');
            break;
          case 10:
            sportsmanBlue = this.getSportsmanByPosition('4_6');
            sportsmanRed = this.getSportsmanByPosition('4_7');
            nextFight = this.getSportsmanByPosition('3_3');
            break;
          case 11:
            sportsmanBlue = this.getSportsmanByPosition('4_8');
            sportsmanRed = this.getSportsmanByPosition('4_9');
            nextFight = this.getSportsmanByPosition('3_4');
            break;
          case 12:
            sportsmanBlue = this.getSportsmanByPosition('4_10');
            sportsmanRed = this.getSportsmanByPosition('4_11');
            nextFight = this.getSportsmanByPosition('3_5');
            break;
          case 13:
            sportsmanBlue = this.getSportsmanByPosition('4_12');
            sportsmanRed = this.getSportsmanByPosition('4_13');
            nextFight = this.getSportsmanByPosition('3_6');
            break;
          case 14:
            sportsmanBlue = this.getSportsmanByPosition('4_14');
            sportsmanRed = this.getSportsmanByPosition('4_15');
            nextFight = this.getSportsmanByPosition('3_7');
            break;
          case 15:
            sportsmanBlue = this.getSportsmanByPosition('5_0');
            sportsmanRed = this.getSportsmanByPosition('5_1');
            nextFight = this.getSportsmanByPosition('4_0');
            break;
          case 16:
            sportsmanBlue = this.getSportsmanByPosition('5_2');
            sportsmanRed = this.getSportsmanByPosition('5_3');
            nextFight = this.getSportsmanByPosition('4_1');
            break;
          case 17:
            sportsmanBlue = this.getSportsmanByPosition('5_4');
            sportsmanRed = this.getSportsmanByPosition('5_5');
            nextFight = this.getSportsmanByPosition('4_2');
            break;
          case 18:
            sportsmanBlue = this.getSportsmanByPosition('5_6');
            sportsmanRed = this.getSportsmanByPosition('5_7');
            nextFight = this.getSportsmanByPosition('4_3');
            break;
          case 19:
            sportsmanBlue = this.getSportsmanByPosition('5_8');
            sportsmanRed = this.getSportsmanByPosition('5_9');
            nextFight = this.getSportsmanByPosition('4_4');
            break;
          case 20:
            sportsmanBlue = this.getSportsmanByPosition('5_10');
            sportsmanRed = this.getSportsmanByPosition('5_11');
            nextFight = this.getSportsmanByPosition('4_5');
            break;
          case 21:
            sportsmanBlue = this.getSportsmanByPosition('5_12');
            sportsmanRed = this.getSportsmanByPosition('5_13');
            nextFight = this.getSportsmanByPosition('4_6');
            break;
          case 22:
            sportsmanBlue = this.getSportsmanByPosition('5_14');
            sportsmanRed = this.getSportsmanByPosition('5_15');
            nextFight = this.getSportsmanByPosition('4_7');
            break;
          case 23:
            sportsmanBlue = this.getSportsmanByPosition('5_16');
            sportsmanRed = this.getSportsmanByPosition('5_17');
            nextFight = this.getSportsmanByPosition('4_8');
            break;
          case 24:
            sportsmanBlue = this.getSportsmanByPosition('5_18');
            sportsmanRed = this.getSportsmanByPosition('5_19');
            nextFight = this.getSportsmanByPosition('4_9');
            break;
          case 25:
            sportsmanBlue = this.getSportsmanByPosition('5_20');
            sportsmanRed = this.getSportsmanByPosition('5_21');
            nextFight = this.getSportsmanByPosition('4_10');
            break;
          case 26:
            sportsmanBlue = this.getSportsmanByPosition('5_22');
            sportsmanRed = this.getSportsmanByPosition('5_23');
            nextFight = this.getSportsmanByPosition('4_11');
            break;
          case 27:
            sportsmanBlue = this.getSportsmanByPosition('5_24');
            sportsmanRed = this.getSportsmanByPosition('5_25');
            nextFight = this.getSportsmanByPosition('4_12');
            break;
          case 28:
            sportsmanBlue = this.getSportsmanByPosition('5_26');
            sportsmanRed = this.getSportsmanByPosition('5_27');
            nextFight = this.getSportsmanByPosition('4_13');
            break;
          case 29:
            sportsmanBlue = this.getSportsmanByPosition('5_28');
            sportsmanRed = this.getSportsmanByPosition('5_29');
            nextFight = this.getSportsmanByPosition('4_14');
            break;
          case 30:
            sportsmanBlue = this.getSportsmanByPosition('5_30');
            sportsmanRed = this.getSportsmanByPosition('5_31');
            nextFight = this.getSportsmanByPosition('4_15');
            break;

          case 31:
            sportsmanBlue = this.getSportsmanByPosition('6_0');
            sportsmanRed = this.getSportsmanByPosition('6_1');
            nextFight = this.getSportsmanByPosition('5_0');
            break;
          case 32:
            sportsmanBlue = this.getSportsmanByPosition('6_2');
            sportsmanRed = this.getSportsmanByPosition('6_3');
            nextFight = this.getSportsmanByPosition('5_1');
            break;
          case 33:
            sportsmanBlue = this.getSportsmanByPosition('6_4');
            sportsmanRed = this.getSportsmanByPosition('6_5');
            nextFight = this.getSportsmanByPosition('5_2');
            break;

          case 34:
            sportsmanBlue = this.getSportsmanByPosition('6_6');
            sportsmanRed = this.getSportsmanByPosition('6_7');
            nextFight = this.getSportsmanByPosition('5_3');
            break;

          case 35:
            sportsmanBlue = this.getSportsmanByPosition('6_8');
            sportsmanRed = this.getSportsmanByPosition('6_9');
            nextFight = this.getSportsmanByPosition('5_4');
            break;
          case 36:
            sportsmanBlue = this.getSportsmanByPosition('6_10');
            sportsmanRed = this.getSportsmanByPosition('6_11');
            nextFight = this.getSportsmanByPosition('5_5');
            break;
          case 37:
            sportsmanBlue = this.getSportsmanByPosition('6_12');
            sportsmanRed = this.getSportsmanByPosition('6_13');
            nextFight = this.getSportsmanByPosition('5_6');
            break;
          case 38:
            sportsmanBlue = this.getSportsmanByPosition('6_14');
            sportsmanRed = this.getSportsmanByPosition('6_15');
            nextFight = this.getSportsmanByPosition('5_7');
            break;

          case 39:
            sportsmanBlue = this.getSportsmanByPosition('6_16');
            sportsmanRed = this.getSportsmanByPosition('6_17');
            nextFight = this.getSportsmanByPosition('5_8');
            break;

          case 40:
            sportsmanBlue = this.getSportsmanByPosition('6_18');
            sportsmanRed = this.getSportsmanByPosition('6_19');
            nextFight = this.getSportsmanByPosition('5_9');
            break;

          case 41:
            sportsmanBlue = this.getSportsmanByPosition('6_20');
            sportsmanRed = this.getSportsmanByPosition('6_21');
            nextFight = this.getSportsmanByPosition('5_10');
            break;

          case 42:
            sportsmanBlue = this.getSportsmanByPosition('6_22');
            sportsmanRed = this.getSportsmanByPosition('6_23');
            nextFight = this.getSportsmanByPosition('5_11');
            break;

          case 43:
            sportsmanBlue = this.getSportsmanByPosition('6_24');
            sportsmanRed = this.getSportsmanByPosition('6_25');
            nextFight = this.getSportsmanByPosition('5_12');
            break;

          case 44:
            sportsmanBlue = this.getSportsmanByPosition('6_26');
            sportsmanRed = this.getSportsmanByPosition('6_27');
            nextFight = this.getSportsmanByPosition('5_13');
            break;

          case 45:
            sportsmanBlue = this.getSportsmanByPosition('6_28');
            sportsmanRed = this.getSportsmanByPosition('6_29');
            nextFight = this.getSportsmanByPosition('5_14');
            break;


          case 46:
            sportsmanBlue = this.getSportsmanByPosition('6_30');
            sportsmanRed = this.getSportsmanByPosition('6_31');
            nextFight = this.getSportsmanByPosition('5_15');
            break;

          case 47:
            sportsmanBlue = this.getSportsmanByPosition('6_32');
            sportsmanRed = this.getSportsmanByPosition('6_33');
            nextFight = this.getSportsmanByPosition('5_16');
            break;

          case 48:
            sportsmanBlue = this.getSportsmanByPosition('6_34');
            sportsmanRed = this.getSportsmanByPosition('6_35');
            nextFight = this.getSportsmanByPosition('5_17');
            break;

          case 49:
            sportsmanBlue = this.getSportsmanByPosition('6_36');
            sportsmanRed = this.getSportsmanByPosition('6_37');
            nextFight = this.getSportsmanByPosition('5_18');
            break;
          case 50:
            sportsmanBlue = this.getSportsmanByPosition('6_38');
            sportsmanRed = this.getSportsmanByPosition('6_39');
            nextFight = this.getSportsmanByPosition('5_19');
            break;
          case 51:
            sportsmanBlue = this.getSportsmanByPosition('6_40');
            sportsmanRed = this.getSportsmanByPosition('6_41');
            nextFight = this.getSportsmanByPosition('5_20');
            break;
          case 52:
            sportsmanBlue = this.getSportsmanByPosition('6_42');
            sportsmanRed = this.getSportsmanByPosition('6_43');
            nextFight = this.getSportsmanByPosition('5_21');
            break;
          case 53:
            sportsmanBlue = this.getSportsmanByPosition('6_44');
            sportsmanRed = this.getSportsmanByPosition('6_45');
            nextFight = this.getSportsmanByPosition('5_22');
            break;
          case 54:
            sportsmanBlue = this.getSportsmanByPosition('6_46');
            sportsmanRed = this.getSportsmanByPosition('6_47');
            nextFight = this.getSportsmanByPosition('5_23');
            break;
          case 55:
            sportsmanBlue = this.getSportsmanByPosition('6_48');
            sportsmanRed = this.getSportsmanByPosition('6_49');
            nextFight = this.getSportsmanByPosition('5_24');
            break;
          case 56:
            sportsmanBlue = this.getSportsmanByPosition('6_50');
            sportsmanRed = this.getSportsmanByPosition('6_51');
            nextFight = this.getSportsmanByPosition('5_25');
            break;
          case 57:
            sportsmanBlue = this.getSportsmanByPosition('6_52');
            sportsmanRed = this.getSportsmanByPosition('6_53');
            nextFight = this.getSportsmanByPosition('5_26');
            break;
          case 58:
            sportsmanBlue = this.getSportsmanByPosition('6_54');
            sportsmanRed = this.getSportsmanByPosition('6_55');
            nextFight = this.getSportsmanByPosition('5_27');
            break;
          case 59:
            sportsmanBlue = this.getSportsmanByPosition('6_56');
            sportsmanRed = this.getSportsmanByPosition('6_57');
            nextFight = this.getSportsmanByPosition('5_28');
            break;
          case 60:
            sportsmanBlue = this.getSportsmanByPosition('6_58');
            sportsmanRed = this.getSportsmanByPosition('6_59');
            nextFight = this.getSportsmanByPosition('5_29');
            break;
          case 61:
            sportsmanBlue = this.getSportsmanByPosition('6_60');
            sportsmanRed = this.getSportsmanByPosition('6_61');
            nextFight = this.getSportsmanByPosition('5_30');
            break;
          case 62:
            sportsmanBlue = this.getSportsmanByPosition('6_62');
            sportsmanRed = this.getSportsmanByPosition('6_63');
            nextFight = this.getSportsmanByPosition('5_31');
            break;


          // case 3:
          //   sportsmanBlue = this.getSportsmanByPosition('2_2');
          //   sportsmanRed = this.getSportsmanByPosition('2_3');
          //   break;

          default:
            console.log('default', 'fight', fight);
            break;
        }

        if(sportsmanBlue.id != undefined){
          this.selectedFight.blue.spId = sportsmanBlue.id;
          this.selectedFight.blue.spName = sportsmanBlue.name;
          this.selectedFight.blue.points = sportsmanBlue.points;
        }
        if(sportsmanRed.id != undefined){
          this.selectedFight.red.spId = sportsmanRed.id;
          this.selectedFight.red.spName = sportsmanRed.name;
          this.selectedFight.red.points = sportsmanRed.points;
        }

        if(!this.selectedFight.red.spId && this.selectedFight.blue.spId){
          this.selectedFight.winSpId = this.selectedFight.blue.spId
        }

          if(!this.selectedFight.blue.spId && this.selectedFight.red.spId){
          this.selectedFight.winSpId = this.selectedFight.red.spId
        }

          // document.getElementById('selectedFightnumber').focus()



        if(nextFight){
          this.selectedFight.number = nextFight.number;
          this.selectedFight.winSpId = nextFight.sp_id;
          this.selectedFight.decision = nextFight.decision == undefined ? "":nextFight.decision;
        }

        console.log('nextFight', nextFight);
        console.log('fight', fight);
        console.log('this.selectedFight', this.selectedFight);
        console.log('this.selectedFight.decision', this.selectedFight.decision);
        // console.log('selectedFight.technicalNumber', this.selectedFight.technicalNumber);
        // console.log('selectedFight.technicalNumber', this.selectedFight.technicalNumber.length);
        // console.log('sportsmanBlue', sportsmanBlue);
        // console.log('sportsmanRed', sportsmanRed);
        // console.log('this.selectedFight', this.selectedFight);



        this.$modal.show('event-update-form-modal');
        // Set focus in fight field

      },

      getSportsmanByPosition(position){
        // ищим спортсмена с текущей позицией
        let result = {name: '', id: 0, organization: ' ', points: ''};


        for (let i = 0; i <this.selectedDrawCategory.drawdata.length ; i++) {
          if(this.selectedDrawCategory.drawdata[i].position == position){
            result.id = this.selectedDrawCategory.drawdata[i].sp_id;
            result.name = this.selectedDrawCategory.drawdata[i].name;
            result.organization = this.selectedDrawCategory.drawdata[i].organization;
            result.decision = this.selectedDrawCategory.drawdata[i].decision;
            result.points = this.selectedDrawCategory.drawdata[i].points;
            result.number = this.selectedDrawCategory.drawdata[i].number;
            result.sp_id = this.selectedDrawCategory.drawdata[i].sp_id;

            return  result;
            break;
          }
        }
        return false;
      },

      selectedFightChanged(){
        console.log(' UserBracketsContructor selectedFightChanged' , this.selectedFight);

        if(this.selectedFight.red.points  > this.selectedFight.blue.points){
          this.selectedFight.winSpId = this.selectedFight.red.spId
          // this.selectedFight.decision = "PTG"

          if(!this.selectedFight.decision){
            this.selectedFight.decision = "PTG"
          }
        }
        if( !this.selectedFight.winSpId && this.selectedFight.red.points  < this.selectedFight.blue.points){
          this.selectedFight.winSpId = this.selectedFight.blue.spId
          // this.selectedFight.decision = "PTG"
          if(!this.selectedFight.decision){
            this.selectedFight.decision = "PTG"
          }
        }



        // this.saveData();
      },

      // переключение на цветной режим
      toogleColorSheme() {
        console.log('toogleColorSheme');
        if (this.colorSheme === true) {
          this.colorSheme = false;
        } else if (this.colorSheme !== true) {
          this.colorSheme = true;
        }
      },

      /**
       * count sportsmans for brackets 2,4,8,16,32
       * @param countSp
       */
      toogleBracket(countSp) {
        console.log('toogleBracket  '+countSp);
        this.showBracket2 = false;
        this.showBracket4 = false;
        this.showBracket8 = false;
        this.showBracket16 = false;
        this.showBracket32 = false;
        this.showBracket64 = false;
        switch (countSp) {
          case 2:
            this.showBracket2 = true;
            this.bracket_size = 2;
            break;
          case 4:
            this.showBracket4 = true;
            this.bracket_size = 4;
            break;
          case 8:
            this.showBracket8 = true;
            this.bracket_size = 8
            break;
          case 16:
            this.showBracket16 = true;
            this.bracket_size = 16;
            break;
          case 32:
            this.showBracket32 = true;
            this.bracket_size = 32;
            break;
          case 64:
            this.showBracket64 = true;
            this.bracket_size = 64;
            break;
        }

        this.$store.dispatch('settings/setActiveBracketSize',  this.bracket_size);

        this.bracketEl = 'bracket-items-'+this.bracket_size;

      },

      // получение изображения сетки
      getImage() {
        let element = document.getElementById("bracket-image-block");

        window.scrollTo(0,0);
        var scrollHeight = Math.max(
          element.scrollHeight, element.scrollHeight,
          element.offsetHeight, element.offsetHeight,
          element.clientHeight, element.clientHeight
        );

        let selectedDrawCategoryName = this.selectedDrawCategory.fullname != undefined ? this.selectedDrawCategory.fullname :'';
        html2canvas(element,{
          windowHeight: window.outerHeight + window.innerHeight,
          }
         ).then(canvas => {
          this.saveAs(canvas.toDataURL('image/png'), selectedDrawCategoryName +'_bracket_v2.png');
        });
      },


      // включение или отключения режима жеребьевки путем добавления класа к елементам которые нужно передвигать
      toogleSortableSheme() {
        console.log('toogleSortableSheme');
        if (this.sortableSheme === true) {
          this.disableToogleSortableSheme();
        } else if (this.sortableSheme !== true) {
          this.enableToogleSortableSheme();
        }

        // this.resortBracket();
      },


      clearBracket()
      {
        console.log('clearBracket');

        let params = {
          tournament_id:this.selectedDrawCategory.tournament_id,
          category:this.selectedDrawCategory.id,
        };
        // autoSortableBracketAll
        if (window.confirm("Скидання жеребкування?")) {
          this.$notify(
              {
                text: 'Скидання жеребкування',
                horizontalAlign: 'right',
                verticalAlign: 'top',
                duration: 10000,
                speed: 1000,
                type: 'error'
              })

          this.$store.dispatch('tournament/clearBracket', params).then(() => this.$store.dispatch('tournament/fetchTournamentOneFull', {id: params.tournament_id}))
              .then(() => this.updateBracket());
        }
      },

      // Автоматическа жеребьевка
      toogleAutoSortableSheme(type) {
        console.log('toogleAutoSortableSheme');
        this.$notify(
            {
              text: 'Старт автоматичного жеребкування',
              horizontalAlign: 'right',
              verticalAlign: 'top',
              duration: 10000,
              speed: 1000,
              type: 'warn'
            })

        let params = {
          tournament_id:this.selectedDrawCategory.tournament_id,
          category:this.selectedDrawCategory.id,
          bracket_size: this.bracket_size,
          time:new Date().getTime(),
          type: type,
        };

        if (window.confirm("Прожерубкувати автоматично?")) {
          this.$store.dispatch('tournament/autoSortableBracket', params).then(() => this.$store.dispatch('tournament/fetchTournamentOneFull', {id: params.tournament_id}))
              .then(() => this.updateBracket());
        }

        // this.$store.dispatch('tournament/autoSortableBracket', params).then(() => this.$store.dispatch('tournament/fetchTournamentOneFull', {id: params.tournament_id}))
        //   .then(() => window.location.reload());


        // if (this.sortableSheme === true) {
        //   this.disableToogleSortableSheme();
        // } else if (this.sortableSheme !== true) {
        //   this.enableToogleSortableSheme();
        // }

      },

      enableToogleSortableSheme(){
        console.log('enableToogleSortableSheme');
        let round0 = document.getElementById('round-0');
        if(round0 != undefined){
          let items = round0.getElementsByClassName('sp-item');
          this.sortableSheme = true;
          for (let k = 0; k < items.length; k++) {
            if (items[k].classList) {
              items[k].classList.add("sortable-drag-item");
            }
          }
        }

      },

      disableToogleSortableSheme(){
        let round0 = document.getElementById('round-0');
        if(round0 != undefined){
          let items = round0.getElementsByClassName('sp-item');
          this.sortableSheme = false;
          for (let k = 0; k < items.length; k++) {
            if (items[k].classList) {
              items[k].classList.remove("sortable-drag-item");
            }
          }
        }

      },


      saveAs(uri, filename) {
        var link = document.createElement('a');
        link.download = 'download';
        if (typeof link.download === 'string') {
          link.href = uri;
          link.target = '_blank';
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(uri);
        }
      },

      createEmptyFights(){
      },
      saveData(){
        // send data to

        let params = {
          tournament_id:this.selectedDrawCategory.tournament_id,
          category:this.selectedDrawCategory.id,
          technicalNumber: this.selectedFight.technicalNumber,
          number: this.selectedFight.number,
          decision: this.selectedFight.decision,
          winSpId: this.selectedFight.winSpId !=null ? this.selectedFight.winSpId:'',
          winSpName: this.selectedFight.winSpName,
          red_id: this.selectedFight.red.spId,
          blue_id: this.selectedFight.blue.spId,
          red_points: this.selectedFight.red.points,
          blue_points: this.selectedFight.blue.points,
          bracket_size: this.bracket_size,
          time:new Date().getTime(),
        };

        this.$notify(
            {
              text: 'Збереження',
              horizontalAlign: 'right',
              verticalAlign: 'top',
              duration: 10000,
              speed: 1000,
              type: 'warning'
            })
        console.log('Save params ', params);

        this.$store.dispatch('tournament/updateBracketFight', params).then(() => this.updateBracket());

      },

      clearDrawCategory()
      {
        this.showBracket2 = false;
        this.showBracket4 = false;
        this.showBracket8 = false;
        this.showBracket16 = false;
        this.showBracket32 = false;
        this.showBracket64 = false;
        this.destroy = true;
        this.$store.dispatch('tournament/fetchTournamentOneFull', {id:this.selectedDrawCategory.tournament_id})
        this.$forceUpdate();
        // this.destroy = false;


      },
      updateBracket()
      {
        this.clearDrawCategory()
        // this.initCategory();

        this.$notify(
            {
              text: 'Дані оновлено',
              horizontalAlign: 'right',
              verticalAlign: 'top',
              duration: 10000,
              speed: 1000,
              type: 'success'
            })

        this.$modal.hide('event-update-form-modal');

        // alert('Дані оновлено');
        // this.notifyVue('top', 'right')

        // this.$notifications.notify(
        //     {
        //       message: `<span>Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for every web developer.</span>`,
        //       icon: 'nc-icon nc-app',
        //       horizontalAlign: 'right',
        //       verticalAlign: 'top',
        //       type: 'success'
        //     })

        // window.location.reload();
      },
      handleChange(){



      }

      // addEmptySportsman(count){
      //   for (let i=0; i <count; i++){
      //     this.sportsmans.push({name: '', id: i, organization: ' ', points: ''});
      //   }
      // }
    },

  }
</script>

<style lang="scss">

</style>
