<template>
  <div class="bracket-body bracket-32" id="bracket-2">

    <div class="title-category">{{ title }} {{ sex == 1 ? 'Чоловіки' : '' }}{{ sex == 2 ? 'Жінки' : '' }}</div>
    <div class="header-bracket">
      <div class="round round-64">Учасник</div>
    </div>

    <div class="bracket bracket-2 " v-bind:class="{'color-sheme':colorSheme}">
      <div class="bracket-left" id="bracket-items-2">
        <div class="round round-0" id="round-0">
          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('1_0') }} :</span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('1_1') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="1_1" :sportsman="getSportsman('1_0')"
                                         :key="'1_1'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('0_0')"
                                       tehnumber="0"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="1_2"
                                         :sportsman="getSportsman('1_1')"></bracket-sportsman-item-draw>
          </div>
        </div>


        <div class="round round-1">
          <div class="group">
            <bracket-sportsman-item-draw technicalNumber="0_0"
                                         :sportsman="getSportsman('0_0')"></bracket-sportsman-item-draw>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {Swappable, Plugins, DragStartEvent, DragStopEvent} from '@shopify/draggable';

export default {
  name: "bracket-2",
  components: {
    Swappable, Plugins, DragStartEvent, DragStopEvent
  },
  props: {
    selectBracketFight: {
      type: Function,
      description: "Function select Bracket Fight"
    },

    resortBracketParent: {
      type: Function,
      description: "Function toogle Sortable Sheme"
    },

    toogleSortableShemeParent: {
      type: Function,
      description: "Function toogle Sortable Sheme"
    },
    colorSheme: {
      type: Boolean,
      description: "Color Sheme"
    },

    destroy: {
      type: Boolean,
      description: "Color Sheme"
    },

    sortableSheme: {
      type: Boolean,
      description: "Sortable Sheme"
    },

    drawdata: {
      type: Array,
      description: "Sortable Sheme"
    },
    // sportsmans: {
    //   type: Array,
    //   description: "List sportsmans"
    // },
    bracketFigths: {
      type: Object,
      description: "List fights"
    },
    title: {
      type: String,
      description: "Card title"
    },
    sex: {
      type: String,
      description: "Card title"
    },
    categoryid: {
      type: [String, Number],
      description: "Card title"
    },
    tournamentid: {
      type: [String, Number],
      description: "Card title"
    },
  },
  data() {
    return {
      selectedFight: '',
      swappable: null,
    }
  },


  watch: {


    categoryid: function (newVal, oldvalue) {
      if (newVal != oldvalue) {
        this.clearSwappable();
      }
    },


    destroy: function (newVal, oldvalue) {
      if (newVal == true) {
        // this.$destroy();
      }
    },

    sortableSheme: function (newVal) {
      if (newVal == true) {
        this.setSwappable();
      }
    },
  },

  beforeDestroy: function () {
    // this.clearSwappable();
  },


  computed: {
    activeBracketSize() {
      return this.$store.getters['settings/activeBracketSize'];
    },

    // sp_position_1_1: {
    //   get () {
    //     return this.fixedCost;
    //   },
    //   set (value) {
    //     this.$store.commit('updateFixedCost', {  fixedCost: value });
    //   }
    // },
  },

  methods: {

    getSportsman(position) {

      // ищим спортсмена с текущей позицией
      let result = {name: '', id: 0, organization: ' ', points: ''};

      for (let i = 0; i < this.drawdata.length; i++) {
        if (this.drawdata[i].position == position) {
          result.id = this.drawdata[i].sp_id;
          result.name = this.drawdata[i].name;
          result.organization = this.drawdata[i].organization;
          result.oblast = this.drawdata[i].oblast;
          result.points = this.drawdata[i].points;
          break;
        }
      }
      return result;

    },


    clearSwappable() {
      if (this.swappable !== null) {
        this.swappable.destroy();
        this.swappable = null;
      }
    },
    clickSelectFight(fight) {
      this.selectBracketFight(fight);
      this.selectedFight = fight;
    },

    setSwappable() {
      let bracketimageblock = document.getElementById('round-0');
      let pendingDrawingBlock = document.getElementById('pendingdrawingblock');

      if (bracketimageblock !== null) {
        // Destroy old element
        if (this.swappable !== null) {
          this.swappable.destroy();
          this.swappable = null;
        }

        this.swappable = new Swappable([bracketimageblock, pendingDrawingBlock], {
          draggable: '.sp-item.sortable-drag-item',
          mirror: {
            constrainDimensions: true,
          },
          delay: 0,
          plugins: [Plugins.ResizeMirror],
          dragClass: "sortable-drag",  // Class name for the dragging item
        });

        this.swappable.on('drag:stop', () => this.saveBracket());
      }
    },

    saveBracket() {
      let round0 = document.getElementById('round-0'); // перша колонка зліва
      let groups = round0.getElementsByClassName('group'); // поединки
      let bracket_data = [];
      let posCount = 0;

      // определяем позиции первой линий в зависимости от сетки
      let let_first_line = '1_';

      switch (this.activeBracketSize) {
        case 2:
          let_first_line = '1_';
          break;
        case 4:
          let_first_line = '2_';
          break;

        case 8:
          let_first_line = '3_';
          break;

        case 16:
          let_first_line = '4_';
          break;

      }


      // перебираем каждый поединок
      for (let i = 0; i < groups.length; i++) {
        // получаем спортсменов в поединке
        let groupSportsmansList = groups[i].getElementsByClassName('sp-item'); // получаем всегда 2 спортсмена

        // filter groupSportsmans
        let groupSportsmans = [];
        for (let j = 0; j < groupSportsmansList.length; j++) {
          if(groupSportsmansList[j].classList.contains('draggable--original')) continue;
          groupSportsmans.push(groupSportsmansList[j]);
        }

        let sp1 = groupSportsmans[0].getAttribute('data-sp');
        if (sp1 != null && sp1 != undefined && sp1 != '') {
          bracket_data.push({position: let_first_line + posCount, id: sp1});
        }
        posCount++;


        let sp2 = groupSportsmans[1].getAttribute('data-sp');
        if (sp2 != null && sp2 != undefined && sp2 != '') {
          bracket_data.push({position: let_first_line + posCount, id: sp2});
        }
        posCount++;
      }

      let sentParams = {
        bracket_size: this.activeBracketSize,
        bracket_data: bracket_data,
        categoryid: this.categoryid,
        tournamentid: this.tournamentid,
        time: new Date().getTime(),
      };

      this.$store.dispatch('tournament/saveBracket', sentParams);
    },

    selectFight(fight) {
      this.selectBracketFight(fight)
    },

    getSportsmanPointsByPosition(position) {

      let sportsman = this.getSportsman(position);
      return sportsman.points;

    },

    getFightNumberByPosition(position) {
      let fight = this.getFight(position);
      return fight.number;

    },
    getFight(position) {
      // ищим спортсмена с текущей позицией
      let result = {number: ''};
      for (let i = 0; i < this.drawdata.length; i++) {
        if (this.drawdata[i].position == position) {
          result.number = this.drawdata[i].number;
          result.decision = this.drawdata[i].decision;
          break;
        }
      }
      return result;
    },
  }
}
</script>

<style scoped>

</style>












