<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
<!--      <a class="navbar-brand" href="#">Dashboard</a>-->
      <button type="button"
              class="navbar-toggler navbar-toggler-right"
              :class="{toggled: $sidebar.showSidebar}"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
              @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>

      <div class="collapse navbar-collapse justify-content-end" :class="{show: $sidebar.showSidebar}">
        <ul class="nav navbar-nav mr-auto">

          <li class="nav-item">
            <sidebar-link to="/user/profile">
              <i class="nc-icon nc-circle-09 mr-1"></i>
              <span>Профіль секретаря</span>

              <span> V 1000.2</span>

            </sidebar-link>
          </li>

          <li class="nav-item">
            <sidebar-link to="/user/tournaments">
              <i class="nc-icon nc-notes mr-1"></i>
              <span>Турніри</span>
            </sidebar-link>
          </li>

        </ul>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a href="#" @click="logout()" class="dropdown-item">
              <span class="not-icon_ not-icon-danger text-danger" > <b> <i class="fa fa-sign-out"></i>Вийти</b></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
  import {baseUrlServer} from "../api/user";

  export default {
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      },
      userOne() {
        return this.$store.getters['user/userOne'];
      },
    },
    created(){
      this.baseUrlServer = baseUrlServer;
    },
    data () {
      return {
        baseUrlServer:'',
        activeNotifications: false
      }
    },

    methods: {
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      logout(){
        localStorage.removeItem('jwt');
        console.log('logout');
        if (localStorage.getItem('jwt') == null) {
          console.log('deleted jwt ')
        }

        this.$router.replace({ path: `/login` });

        return false;
      }
    }
  }

</script>
<style>

</style>
