<template>
  <div class="bracket-body bracket-4" id="bracket-32">

    <div class="title-category">{{ title }} {{ sex == 1 ? 'Чоловіки' : '' }}{{ sex == 2 ? 'Жінки' : '' }}</div>
    <div class="header-bracket">
      <div class="round round-32">Учасник</div>
    </div>

    <div class="bracket bracket-32 " v-bind:class="{'color-sheme':colorSheme}">
      <div class="bracket-left" id="bracket-items-2">


        <div class="round round-0" id="round-0">
          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_0') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_1') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_0" :sportsman="getSportsman('5_0')"
                                         :key="'5_0'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_0')"
                                       tehnumber="15"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_1"
                                         :sportsman="getSportsman('5_1')"></bracket-sportsman-item-draw>
          </div>

          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_2') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_3') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_2" :sportsman="getSportsman('5_2')"
                                         :key="'5_2'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_1')"
                                       tehnumber="16"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_3"
                                         :sportsman="getSportsman('5_3')"></bracket-sportsman-item-draw>
          </div>

          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_4') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_5') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_4" :sportsman="getSportsman('5_4')"
                                         :key="'5_4'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_2')"
                                       tehnumber="17"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_5"
                                         :sportsman="getSportsman('5_5')"></bracket-sportsman-item-draw>
          </div>


          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_6') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_7') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_6" :sportsman="getSportsman('5_6')"
                                         :key="'5_6'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_3')"
                                       tehnumber="18"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_7"
                                         :sportsman="getSportsman('5_7')"></bracket-sportsman-item-draw>
          </div>

          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_8') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_9') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_8" :sportsman="getSportsman('5_8')"
                                         :key="'5_8'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_4')"
                                       tehnumber="19"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_9"
                                         :sportsman="getSportsman('5_9')"></bracket-sportsman-item-draw>
          </div>

          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_10') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_11') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_10"
                                         :sportsman="getSportsman('5_10')" :key="'5_10'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_5')"
                                       tehnumber="20"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_11"
                                         :sportsman="getSportsman('5_11')"></bracket-sportsman-item-draw>
          </div>


          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_12') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_13') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_12"
                                         :sportsman="getSportsman('5_12')" :key="'5_12'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_6')"
                                       tehnumber="21"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_13"
                                         :sportsman="getSportsman('5_13')"></bracket-sportsman-item-draw>
          </div>


          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_14') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_15') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_14"
                                         :sportsman="getSportsman('5_14')"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_7')"
                                       tehnumber="22"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_15"
                                         :sportsman="getSportsman('5_15')"></bracket-sportsman-item-draw>
          </div>

          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_16') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_17') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_16"
                                         :sportsman="getSportsman('5_16')"
                                         :key="'5_16'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_8')"
                                       tehnumber="23"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_17"
                                         :sportsman="getSportsman('5_17')"></bracket-sportsman-item-draw>
          </div>

          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_18') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_19') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_18"
                                         :sportsman="getSportsman('5_18')"
                                         :key="'5_18'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_9')"
                                       tehnumber="24"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_19"
                                         :sportsman="getSportsman('5_19')"></bracket-sportsman-item-draw>
          </div>

          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_20') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_21') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_20"
                                         :sportsman="getSportsman('5_20')"
                                         :key="'5_20'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_10')"
                                       tehnumber="25"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_21"
                                         :sportsman="getSportsman('5_21')"></bracket-sportsman-item-draw>
          </div>


          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_22') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_23') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_22"
                                         :sportsman="getSportsman('5_22')"
                                         :key="'5_22'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_11')"
                                       tehnumber="26"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_23"
                                         :sportsman="getSportsman('5_23')"></bracket-sportsman-item-draw>
          </div>

          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_24') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_25') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_24"
                                         :sportsman="getSportsman('5_24')"
                                         :key="'5_24'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_12')"
                                       tehnumber="27"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_25"
                                         :sportsman="getSportsman('5_25')"></bracket-sportsman-item-draw>
          </div>

          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_26') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_27') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_26"
                                         :sportsman="getSportsman('5_26')" :key="'5_26'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_13')"
                                       tehnumber="28"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_27"
                                         :sportsman="getSportsman('5_27')"></bracket-sportsman-item-draw>
          </div>


          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_28') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_29') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_28"
                                         :sportsman="getSportsman('5_28')" :key="'5_28'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_14')"
                                       tehnumber="29"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_29"
                                         :sportsman="getSportsman('5_29')"></bracket-sportsman-item-draw>
          </div>


          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition('5_30') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text">{{ getSportsmanPointsByPosition('5_31') }}</span>

            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_30"
                                         :sportsman="getSportsman('5_30')"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('4_15')"
                                       tehnumber="30"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="true" technicalNumber="5_31"
                                         :sportsman="getSportsman('5_31')"></bracket-sportsman-item-draw>
          </div>


        </div>


        <div class="round round-1" id="round-1">
          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text" style="top: 100px;">{{ getSportsmanPointsByPosition('4_0') }} <i style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 100px; right: -90px">{{ getSportsmanPointsByPosition('4_1') }}</span>

            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_0" :sportsman="getSportsman('4_0')"
                                         :key="'4_0'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('3_0')"
                                       tehnumber="7"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_1"
                                         :sportsman="getSportsman('4_1')"></bracket-sportsman-item-draw>
          </div>

          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text" style="top: 100px;">{{ getSportsmanPointsByPosition('4_2') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 100px;">{{ getSportsmanPointsByPosition('4_3') }}</span>

            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_2" :sportsman="getSportsman('4_2')"
                                         :key="'4_2'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('3_1')"
                                       tehnumber="8"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_3"
                                         :sportsman="getSportsman('4_3')"></bracket-sportsman-item-draw>
          </div>

          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text" style="top: 100px;">{{ getSportsmanPointsByPosition('4_4') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 100px; right: -90px;">{{ getSportsmanPointsByPosition('4_5') }}</span>

            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_4" :sportsman="getSportsman('4_4')"
                                         :key="'4_4'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('3_2')"
                                       tehnumber="9"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_5"
                                         :sportsman="getSportsman('4_5')"></bracket-sportsman-item-draw>
          </div>


          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text" style="top: 100px;">{{ getSportsmanPointsByPosition('4_6') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 100px;">{{ getSportsmanPointsByPosition('4_7') }}</span>

            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_6" :sportsman="getSportsman('4_6')"
                                         :key="'4_6'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('3_3')"
                                       tehnumber="10"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_7"
                                         :sportsman="getSportsman('4_7')"></bracket-sportsman-item-draw>
          </div>

          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text" style="top: 100px;">{{ getSportsmanPointsByPosition('4_8') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 100px;">{{ getSportsmanPointsByPosition('4_9') }}</span>

            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_8" :sportsman="getSportsman('4_8')"
                                         :key="'4_8'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('3_4')"
                                       tehnumber="11"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_9"
                                         :sportsman="getSportsman('4_9')"></bracket-sportsman-item-draw>
          </div>

          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text" style="top: 100px;">{{ getSportsmanPointsByPosition('4_10') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 100px;">{{ getSportsmanPointsByPosition('4_11') }}</span>

            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_10"
                                         :sportsman="getSportsman('4_10')" :key="'4_10'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('3_5')"
                                       tehnumber="12"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_11"
                                         :sportsman="getSportsman('4_11')"></bracket-sportsman-item-draw>
          </div>


          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text" style="top: 100px;">{{ getSportsmanPointsByPosition('4_12') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 100px; right: -90px" >{{ getSportsmanPointsByPosition('4_13') }}</span>

            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_12"
                                         :sportsman="getSportsman('4_12')" :key="'4_12'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('3_6')"
                                       tehnumber="13"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_13"
                                         :sportsman="getSportsman('4_13')"></bracket-sportsman-item-draw>
          </div>


          <div class="group " data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text"  style="top: 100px;">{{ getSportsmanPointsByPosition('4_14') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 100px;">{{ getSportsmanPointsByPosition('4_15') }}</span>

            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_14"
                                         :sportsman="getSportsman('4_14')"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('3_7')"
                                       tehnumber="14"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw :showOrganization="false" technicalNumber="4_15"
                                         :sportsman="getSportsman('4_15')"></bracket-sportsman-item-draw>
          </div>


        </div>

        <div class="round round-2">
          <div class="group" data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text" style="top: 200px;">{{ getSportsmanPointsByPosition('3_0') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 200px;">{{ getSportsmanPointsByPosition('3_1') }}</span>

            <bracket-sportsman-item-draw technicalNumber="3_0" :sportsman="getSportsman('3_0')"
                                         :key="'3_0'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('2_0')"
                                       tehnumber="3"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw technicalNumber="3_1"
                                         :sportsman="getSportsman('3_1')"></bracket-sportsman-item-draw>
          </div>

          <div class="group" data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text" style="top: 200px;">{{ getSportsmanPointsByPosition('3_2') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 200px;">{{ getSportsmanPointsByPosition('3_3') }}</span>

            <bracket-sportsman-item-draw technicalNumber="3_2" :sportsman="getSportsman('3_2')"
                                         :key="'3_2'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('2_1')"
                                       tehnumber="4"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw technicalNumber="3_3"
                                         :sportsman="getSportsman('3_3')"></bracket-sportsman-item-draw>
          </div>


          <div class="group" data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text" style="top: 200px;">{{ getSportsmanPointsByPosition('3_4') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 200px; right: -90px">{{ getSportsmanPointsByPosition('3_5') }}</span>

            <bracket-sportsman-item-draw technicalNumber="3_4" :sportsman="getSportsman('3_4')"
                                         :key="'3_4'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('2_2')"
                                       tehnumber="5"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw technicalNumber="3_5"
                                         :sportsman="getSportsman('3_5')"></bracket-sportsman-item-draw>
          </div>


          <div class="group" data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text" style="top: 200px;">{{ getSportsmanPointsByPosition('3_6') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 200px;">{{ getSportsmanPointsByPosition('3_7') }}</span>

            <bracket-sportsman-item-draw technicalNumber="3_6" :sportsman="getSportsman('3_6')"
                                         :key="'3_6'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('2_3')"
                                       tehnumber="6"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw technicalNumber="3_7"
                                         :sportsman="getSportsman('3_7')"></bracket-sportsman-item-draw>
          </div>


        </div>

        <div class="round round-3">
          <div class="group" data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text" style="top: 400px;right: -70px;">{{ getSportsmanPointsByPosition('2_0') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 400px;right: -90px;">{{ getSportsmanPointsByPosition('2_1') }}</span>

            <bracket-sportsman-item-draw technicalNumber="1_0" :sportsman="getSportsman('2_0')"
                                         :key="'2_0'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('1_0')"
                                       tehnumber="1"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw technicalNumber="2_1"
                                         :sportsman="getSportsman('2_1')"></bracket-sportsman-item-draw>
          </div>

          <div class="group" data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text" style="top: 400px;">{{ getSportsmanPointsByPosition('2_2') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 400px;">{{ getSportsmanPointsByPosition('2_3') }}</span>

            <bracket-sportsman-item-draw technicalNumber="2_2" :sportsman="getSportsman('2_2')"
                                         :key="'2_2'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('1_1')"
                                       tehnumber="2"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw technicalNumber="2_3"
                                         :sportsman="getSportsman('2_3')"></bracket-sportsman-item-draw>
          </div>


        </div>


        <div class="round round-4">
          <div class="group" data-fight="1">
            <div class="line-vertical"></div>

            <span class="fight-points-blue-text" style="top: 800px;">{{ getSportsmanPointsByPosition('1_0') }} <i
                style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
            <span class="fight-points-red-text" style="top: 800px;">{{ getSportsmanPointsByPosition('1_1') }}</span>

            <bracket-sportsman-item-draw technicalNumber="1_0" :sportsman="getSportsman('1_0')"
                                         :key="'1_0'"></bracket-sportsman-item-draw>
            <div class="divider"></div>
            <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition('0_0')"
                                       tehnumber="0"></bracket-fight-number-draw>
            <bracket-sportsman-item-draw technicalNumber="1_1"
                                         :sportsman="getSportsman('1_1')"></bracket-sportsman-item-draw>
          </div>
        </div>


        <div class="round round-5">
          <div class="group">
            <bracket-sportsman-item-draw technicalNumber="0_0"
                                         :sportsman="getSportsman('0_0')"></bracket-sportsman-item-draw>
          </div>
        </div>


      </div>
    </div>
  </div>

</template>

<script>
import {DragStartEvent, DragStopEvent, Plugins, Swappable} from '@shopify/draggable';

export default {
  name: "bracket-2",
  components: {
    Swappable, Plugins, DragStartEvent, DragStopEvent
  },
  props: {
    selectBracketFight: {
      type: Function,
      description: "Function select Bracket Fight"
    },

    resortBracketParent: {
      type: Function,
      description: "Function toogle Sortable Sheme"
    },

    toogleSortableShemeParent: {
      type: Function,
      description: "Function toogle Sortable Sheme"
    },
    colorSheme: {
      type: Boolean,
      description: "Color Sheme"
    },

    destroy: {
      type: Boolean,
      description: "Color Sheme"
    },

    sortableSheme: {
      type: Boolean,
      description: "Sortable Sheme"
    },

    drawdata: {
      type: Array,
      description: "Sortable Sheme"
    },
    // sportsmans: {
    //   type: Array,
    //   description: "List sportsmans"
    // },
    bracketFigths: {
      type: Object,
      description: "List fights"
    },
    title: {
      type: String,
      description: "Card title"
    },
    sex: {
      type: String,
      description: "Card title"
    },
    categoryid: {
      type: [String, Number],
      description: "Card title"
    },
    tournamentid: {
      type: [String, Number],
      description: "Card title"
    },
  },
  data() {
    return {
      selectedFight: '',
      swappable: null,
    }
  },


  watch: {


    categoryid: function (newVal, oldvalue) {
      if (newVal != oldvalue) {
        this.clearSwappable();
      }
    },


    destroy: function (newVal, oldvalue) {
      if (newVal == true) {
        // this.$destroy();
      }
    },

    sortableSheme: function (newVal) {
      if (newVal == true) {
        this.setSwappable();
      }
    },
  },

  beforeDestroy: function () {
    // this.clearSwappable();
  },


  computed: {
    activeBracketSize() {
      return this.$store.getters['settings/activeBracketSize'];
    },

    // sp_position_1_1: {
    //   get () {
    //     return this.fixedCost;
    //   },
    //   set (value) {
    //     this.$store.commit('updateFixedCost', {  fixedCost: value });
    //   }
    // },
  },

  methods: {

    getSportsman(position) {

      // ищим спортсмена с текущей позицией
      let result = {name: '', id: 0, organization: ' ', points: ''};

      for (let i = 0; i < this.drawdata.length; i++) {
        if (this.drawdata[i].position == position) {
          result.id = this.drawdata[i].sp_id;
          result.name = this.drawdata[i].name;
          result.organization = this.drawdata[i].organization;
          result.oblast = this.drawdata[i].oblast;
          result.points = this.drawdata[i].points;
          break;
        }
      }
      return result;

    },


    clearSwappable() {
      if (this.swappable !== null) {
        this.swappable.destroy();
        this.swappable = null;
      }
    },
    clickSelectFight(fight) {
      this.selectBracketFight(fight);
      this.selectedFight = fight;
    },

    setSwappable() {
      let bracketimageblock = document.getElementById('round-0');
      let pendingDrawingBlock = document.getElementById('pendingdrawingblock');

      if (bracketimageblock !== null) {
        // Destroy old element
        if (this.swappable !== null) {
          this.swappable.destroy();
          this.swappable = null;
        }

        this.swappable = new Swappable([bracketimageblock, pendingDrawingBlock], {
          draggable: '.sp-item.sortable-drag-item',
          mirror: {
            constrainDimensions: true,
          },
          delay: 0,
          plugins: [Plugins.ResizeMirror],
          dragClass: "sortable-drag",  // Class name for the dragging item
        });

        this.swappable.on('drag:stop', () => this.saveBracket());
      }
    },

    saveBracket() {
      let round0 = document.getElementById('round-0'); // перша колонка зліва
      let groups = round0.getElementsByClassName('group'); // поединки
      let bracket_data = [];
      let posCount = 0;

      // определяем позиции первой линий в зависимости от сетки
      let let_first_line = '1_';

      switch (this.activeBracketSize) {
        case 2:
          let_first_line = '1_';
          break;
        case 4:
          let_first_line = '2_';
          break;

        case 8:
          let_first_line = '3_';
          break;

        case 16:
          let_first_line = '4_';
          break;

        case 32:
          let_first_line = '5_';
          break;

      }


      // перебираем каждый поединок
      for (let i = 0; i < groups.length; i++) {
        // получаем спортсменов в поединке
        let groupSportsmansList = groups[i].getElementsByClassName('sp-item'); // получаем всегда 2 спортсмена

        // filter groupSportsmans
        let groupSportsmans = [];
        for (let j = 0; j < groupSportsmansList.length; j++) {
          if(groupSportsmansList[j].classList.contains('draggable--original')) continue;
          groupSportsmans.push(groupSportsmansList[j]);
        }

        let sp1 = groupSportsmans[0].getAttribute('data-sp');
        if (sp1 != null && sp1 != undefined && sp1 != '') {
          bracket_data.push({position: let_first_line + posCount, id: sp1});
        }
        posCount++;


        let sp2 = groupSportsmans[1].getAttribute('data-sp');
        if (sp2 != null && sp2 != undefined && sp2 != '') {
          bracket_data.push({position: let_first_line + posCount, id: sp2});
        }
        posCount++;
      }

      let sentParams = {
        bracket_size: this.activeBracketSize,
        bracket_data: bracket_data,
        categoryid: this.categoryid,
        tournamentid: this.tournamentid,
        time: new Date().getTime(),
      };

      this.$store.dispatch('tournament/saveBracket', sentParams);
    },

    selectFight(fight) {
      this.selectBracketFight(fight)
    },

    getSportsmanPointsByPosition(position) {

      let sportsman = this.getSportsman(position);
      return sportsman.points;

    },
    getFightNumberByPosition(position) {
      let fight = this.getFight(position);
      return fight;

    },
    getFight(position) {
      // ищим спортсмена с текущей позицией
      let result = {number: ''};
      for (let i = 0; i < this.drawdata.length; i++) {
        if (this.drawdata[i].position == position) {
          result.number = this.drawdata[i].number;
          result.decision = this.drawdata[i].decision;
          break;
        }
      }
      return result;
    },
  }
}
</script>
