import TournamentAPI from '../api/tournament';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    category: false,
    error: null,
    requestSend: false,
    requestSuccessful: false,
    requestFail: false,
    isModalFormShow: true,
    tournaments: [],
    tournamentOne: {}
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    requestSend(state) {
      return state.requestSend;
    },
    requestSuccessful(state) {
      return state.requestSuccessful;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    hasTournaments(state) {
      return state.tournaments.data && Object.keys(state.tournaments.data.list).length > 0 ? true : false;
    },
    isModalFormShow(state) {
      return state.isModalFormShow;
    },
    tournaments(state) {
      return state.tournaments;
    },
    tournamentOne(state) {
      return state.tournamentOne;
    },
    categoryOne(state) {
      return state.category;
    },
  },
  mutations: {
    ['BRACKET_UPDATE_SUCCESS'](state) {
      state.isLoading = true;
      state.requestSend = true;
      // state.error = null;
    },
    ['EVENT_STARTED_SUCCESS'](state) {
      // state.isLoading = true;
      state.requestSend = true;
      // state.error = null;
    },

    ['BRACKET_UPDATE_ERROR'](state) {
      state.isLoading = true;
      state.requestSend = true;
      // state.error = null;
    },


    ['CREATING_SPORTSMAN'](state) {
      state.isLoading = true;
      state.requestSend = true;
      state.error = null;
    },

    ['SET_CATEGORY_DAYS'](state) {
      state.isLoading = true;
      state.requestSend = true;
      state.error = null;
    },
    ['SET_CATEGORY_DAYS_SUCCESS'](state) {
      state.isLoading = false;
      state.requestSend = true;
      state.error = null;
    },
    ['SET_CATEGORY_DAYS_ERROR'](state) {
      state.isLoading = false;
      state.requestSend = true;
      state.error = null;
    },
    ['CREATING_SPORTSMAN_SUCCESS'](state, post) {
      state.isLoading = false;
      state.requestSuccessful = true;
      state.isModalFormShow = false;
      state.requestSend = false;
      state.requestFail = false;
      state.error = null;
      // state.tournaments.unshift(post);
      // state.tournamentOne = post;
    },
    ['CREATING_SPORTSMAN_ERROR'](state, error) {
      state.isLoading = false;
      state.requestSuccessful = false;
      state.requestSend = false;
      state.requestFail = true;
      state.error = error;
      // state.tournaments = [];
    },


 ['CREATING_CATEGORY'](state) {
      state.isLoading = true;
      state.requestSend = true;
      state.error = null;
    },
    ['CREATING_CATEGORY_SUCCESS'](state, post) {
      state.isLoading = false;
      state.requestSuccessful = true;
      state.isModalFormShow = false;
      state.requestSend = false;
      state.requestFail = false;
      state.error = null;
      // state.tournaments.unshift(post);
      // state.tournamentOne = post;
    },
    ['CREATING_CATEGORY_ERROR'](state, error) {
      state.isLoading = false;
      state.requestSuccessful = false;
      state.requestSend = false;
      state.requestFail = true;
      state.error = error;
      // state.tournaments = [];
    },


    ['CREATING_TOURNAMENT'](state) {
      state.isLoading = true;
      state.requestSend = true;
      state.error = null;
    },
    ['CREATING_TOURNAMENT_SUCCESS'](state, post) {
      state.isLoading = false;
      state.requestSuccessful = true;
      state.isModalFormShow = false;
      state.requestSend = false;
      state.requestFail = false;
      state.error = null;
      state.tournaments.unshift(post);
      state.tournamentOne = post;
    },
    ['CREATING_TOURNAMENT_ERROR'](state, error) {
      state.isLoading = false;
      state.requestSuccessful = false;
      state.requestSend = false;
      state.requestFail = true;
      state.error = error;
      state.tournaments = [];
    },

    ['UPDATING_TOURNAMENT'](state) {
      state.isLoading = false;
      state.requestSend = true;
      state.error = null;
    },
    ['UPDATING_TOURNAMENT_SUCCESS'](state, post) {
      state.isLoading = false;
      state.requestSuccessful = true;
      state.isModalFormShow = false;
      state.requestSend = false;
      state.requestFail = false;
      state.error = null;
      state.tournamentOne = post;
    },
    ['UPDATING_TOURNAMENT_ERROR'](state, error) {
      state.isLoading = false;
      state.requestSuccessful = false;
      state.requestSend = false;
      state.requestFail = true;
      state.error = error;
      state.tournaments = [];
    },

    ['FETCHING_TOURNAMENTS'](state) {
      state.isLoading = true;
      state.error = null;
      state.tournaments = [];
    },
    ['FETCHING_TOURNAMENTS_SUCCESS'](state, tournaments) {
      state.isLoading = false;
      state.error = null;
      state.tournaments = tournaments;
    },
    ['FETCHING_TOURNAMENTS_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
      state.tournaments = [];
    },



    ['FETCHING_CATEGORY_ONE'](state) {
      state.isLoading = true;
      state.error = null;
      state.category = [];
    },
    ['FETCHING_CATEGORY_ONE_SUCCESS'](state, category) {
      state.isLoading = false;
      state.error = null;
      state.category = category;
    },
    ['FETCHING_CATEGORY_ONE_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
      state.category = [];
    },


    ['FETCHING_TOURNAMENT_ONE'](state) {
      state.isLoading = true;
      state.error = null;
      state.tournament = {};
    },
    ['FETCHING_TOURNAMENT_ONE_SUCCESS'](state, tournamentOne) {
      state.isLoading = false;
      state.error = null;
      console.log(state.tournamentOne);
      state.tournamentOne = tournamentOne;
    },
    ['FETCHING_TOURNAMENT_ONE_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
      state.tournament = {};
    },

    ['SHOW_MODAL_FORM_TOURNAMENT'](state, error) {
      state.isModalFormShow = true;
    },
    ['HIDE_MODAL_FORM_TOURNAMENT'](state, error) {
      state.isModalFormShow = false;
    },
    ['DELETING_TOURNAMENT'](state, error) {
      // state.isModalFormShow = true;
    },
    ['DELETING_TOURNAMENT_SUCCESS'](state, error) {
      // state.isModalFormShow = false;
    },
    ['DELETING_TOURNAMENT_ERROR'](state, error) {
      // state.isModalFormShow = false;
    },
  },
  actions: {
    deleteTournament({commit}, params) {
      commit('DELETING_TOURNAMENT');
      return TournamentAPI.delete(params)
        .then(res => commit('DELETING_TOURNAMENT_SUCCESS', res.data))
        .catch(err => commit('DELETING_TOURNAMENT_ERROR', err));
    },

    deleteTournamentAllSportsmans({commit}, params) {
      commit('DELETING_TOURNAMENT');
      return TournamentAPI.deleteTournamentAllSportsmans(params)
        .then(res => commit('DELETING_TOURNAMENT_SUCCESS', res.data))
        .catch(err => commit('DELETING_TOURNAMENT_ERROR', err));
    },
    deleteOneSportsman({commit}, params) {
      commit('DELETING_TOURNAMENT');
      return TournamentAPI.deleteOneSportsman(params)
        .then(res => commit('DELETING_TOURNAMENT_SUCCESS', res.data))
        .catch(err => commit('DELETING_TOURNAMENT_ERROR', err));
    },
    deleteOneCategory({commit}, params) {
      commit('DELETING_TOURNAMENT');
      return TournamentAPI.deleteOneCategory(params)
        .then(res => commit('DELETING_TOURNAMENT_SUCCESS', res.data))
        .catch(err => commit('DELETING_TOURNAMENT_ERROR', err));
    },
    createTournament({commit}, params) {
      commit('CREATING_TOURNAMENT');
      return TournamentAPI.create(params)
        .then(res => commit('CREATING_TOURNAMENT_SUCCESS', res.data))
        .catch(err => commit('CREATING_TOURNAMENT_ERROR', err));
    },
    addSportsman({commit}, params) {
      commit('CREATING_SPORTSMAN');
      return TournamentAPI.createSportsman(params)
        .then(res => commit('CREATING_SPORTSMAN_SUCCESS', res.data))
        .catch(err => commit('CREATING_SPORTSMAN_ERROR', err));
    },
    addCategory({commit}, params) {
      commit('CREATING_CATEGORY');
      return TournamentAPI.createCategory(params)
        .then(res => commit('CREATING_CATEGORY_SUCCESS', res.data))
        .catch(err => commit('CREATING_CATEGORY_ERROR', err));
    },
    setCategoryDays({commit}, params) {
      commit('SET_CATEGORY_DAYS');
      return TournamentAPI.setCategoryDays(params)
        .then(res => commit('SET_CATEGORY_DAYS_SUCCESS', res.data))
        .catch(err => commit('SET_CATEGORY_DAYS_ERROR', err));
    },
    updateTournament({commit}, params) {
      commit('UPDATING_TOURNAMENT');
      return TournamentAPI.update(params)
        .then(res => commit('UPDATING_TOURNAMENT_SUCCESS', res.data))
        .catch(err => commit('UPDATING_TOURNAMENT_ERROR', err));
    },
    showModalForm({commit}, val) {
      if (val === true) {
        commit('SHOW_MODAL_FORM_TOURNAMENT');
      } else {
        commit('HIDE_MODAL_FORM_TOURNAMENT');
      }

      return true;
    },
    fetchTournaments({commit}) {
      commit('FETCHING_TOURNAMENTS');
      return TournamentAPI.getAll()
        .then(res => commit('FETCHING_TOURNAMENTS_SUCCESS', res.data))
        .catch(err => commit('FETCHING_TOURNAMENTS_ERROR', err));
    },

    fetchTournamentOne({commit}, params) {
      commit('FETCHING_TOURNAMENT_ONE');
      return TournamentAPI.getOne(params)
        .then(res => commit('FETCHING_TOURNAMENT_ONE_SUCCESS', res.data))
        .catch(err => commit('FETCHING_TOURNAMENT_ONE_ERROR', err));
    },

    fetchCategoryOne({commit}, params) {
      commit('FETCHING_CATEGORY_ONE');
      return TournamentAPI.getCategoryOne(params)
        .then(res => commit('FETCHING_CATEGORY_ONE_SUCCESS', res.data))
        .catch(err => commit('FETCHING_CATEGORY_ONE_ERROR', err));
    },

    fetchTournamentOneFull({commit}, params) {
      commit('FETCHING_TOURNAMENT_ONE');
      return TournamentAPI.getOneFull(params)
        .then(res => commit('FETCHING_TOURNAMENT_ONE_SUCCESS', res.data))
        .catch(err => commit('FETCHING_TOURNAMENT_ONE_ERROR', err));
    },

    syncTournamentSportsmans({commit}, params) {
      commit('FETCHING_TOURNAMENT_SYNC_ONE');
      return TournamentAPI.getDataSync(params)
        .then(res => commit('FETCHING_TOURNAMENT_SYNC_ONE_SUCCESS', res.data))
        .catch(err => commit('FETCHING_TOURNAMENT_SYNC_ONE_ERROR', err));
    },


    verifyOneSportsman({commit}, params) {
      commit('FETCHING_VERIFY_SPORTSMAN');
      return TournamentAPI.verifyOneSportsman(params)
        .then(res => commit('FETCHING_VERIFY_SPORTSMAN_SUCCESS', res.data))
        .catch(err => commit('FETCHING_VERIFY_SPORTSMAN_ERROR', err));
    },



    uploadFileCSV({commit}, formData) {
      commit('FETCHING_TOURNAMENT_ONE');
      return TournamentAPI.uploadFile(formData)
        .then(res => commit('FETCHING_TOURNAMENT_ONE_SUCCESS', res.data))
        .catch(err => commit('FETCHING_TOURNAMENT_ONE_ERROR', err));
    },


    sortableCategoryListSave({commit}, params) {
      commit('CREATING_SPORTSMAN');
      return TournamentAPI.sortableCategoryListSave(params)
        .then(res => commit('CREATING_SPORTSMAN_SUCCESS', res.data))
        .catch(err => commit('CREATING_SPORTSMAN_ERROR', err));
    },


    createCategoryListFirst({commit}, params) {
      commit('CREATING_SPORTSMAN');
      return TournamentAPI.createCategoryListFirst(params)
        .then(res => commit('CREATING_SPORTSMAN_SUCCESS', res.data))
        .catch(err => commit('CREATING_SPORTSMAN_ERROR', err));
    },



    saveBracket({commit}, params) {
      // commit('CREATING_SPORTSMAN');
      return TournamentAPI.saveBracket(params)
        .then(res => commit('BRACKET_UPDATE_SUCCESS', res.data))
        .catch(err => commit('BRACKET_UPDATE_ERROR', err));
    },

    updateBracketFight({commit}, params) {
      // commit('CREATING_SPORTSMAN');
      return TournamentAPI.updateBracketFight(params)
        .then(res => commit('BRACKET_UPDATE_SUCCESS', res.data))
        .catch(err => commit('BRACKET_UPDATE_ERROR', err));
    },

    autoSortableBracket({commit}, params) {
      // commit('CREATING_SPORTSMAN');
      return TournamentAPI.autoSortableBracket(params)
        .then(res => commit('BRACKET_UPDATE_SUCCESS', res.data))
        .catch(err => commit('BRACKET_UPDATE_ERROR', err));
    },

    autoSortableBracketAll({commit}, params) {
      // commit('CREATING_SPORTSMAN');
      return TournamentAPI.autoSortableBracketAll(params)
        .then(res => commit('BRACKET_UPDATE_SUCCESS', res.data))
        .catch(err => commit('BRACKET_UPDATE_ERROR', err));
    },

    clearBracket({commit}, params) {
      // commit('CREATING_SPORTSMAN');
      return TournamentAPI.clearBracket(params)
        .then(res => commit('BRACKET_UPDATE_SUCCESS', res.data))
        .catch(err => commit('BRACKET_UPDATE_ERROR', err));
    },

    removeFromBracketSportsman({commit}, params) {
      // commit('CREATING_SPORTSMAN');
      return TournamentAPI.removeFromBracketSportsman(params)
        .then(res => commit('BRACKET_UPDATE_SUCCESS', res.data))
        .catch(err => commit('BRACKET_UPDATE_ERROR', err));
    },

    setPositionListSp({commit}, params) {
      // commit('CREATING_SPORTSMAN');
      return TournamentAPI.setPositionListSp(params)
        .then(res => commit('BRACKET_UPDATE_SUCCESS', res.data))
        .catch(err => commit('BRACKET_UPDATE_ERROR', err));
    },

    sePointsListSp({commit}, params) {
      // commit('CREATING_SPORTSMAN');
      return TournamentAPI.sePointsListSp(params)
        .then(res => commit('BRACKET_UPDATE_SUCCESS', res.data))
        .catch(err => commit('BRACKET_UPDATE_ERROR', err));
    },

    deleteSportsmanFromCategory({commit}, params) {
      // commit('CREATING_SPORTSMAN');
      return TournamentAPI.deleteSportsmanFromCategory(params)
        .then(res => commit('BRACKET_UPDATE_SUCCESS', res.data))
        .catch(err => commit('BRACKET_UPDATE_ERROR', err));
    },

    startEpEvent({commit}, params) {
      // commit('CREATING_SPORTSMAN');
      return TournamentAPI.startEpEvent(params)
        .then(res => commit('EVENT_STARTED_SUCCESS', res.data))
        .catch(err => commit('EVENT_STARTED_ERROR', err));
    },

    randomEpEvent({commit}, params) {
      // commit('CREATING_SPORTSMAN');
      return TournamentAPI.randomEpEvent(params)
        .then(res => commit('EVENT_STARTED_SUCCESS', res.data))
        .catch(err => commit('EVENT_STARTED_ERROR', err));
    },

  },
}
