import axios from 'axios';


let baseUrl = '';
if( process.env.NODE_ENV == 'development')
{
  baseUrl = 'https://app.taekwondo.com.ua';
}else{
  baseUrl = 'https://app.taekwondo.com.ua';
}
const base = baseUrl;
// const base = 'http://api.app.loc';
export default {

  deleteSportsmanFromCategory(params) {
        return axios.post(
          base+'/api/delete-sportsman-from-category',
            params
        );
    },

    startEpEvent(params) {
        return axios.post(
            base+'/wf-api/ep/start-event',
            params
        );
    },

  randomEpEvent(params) {
        return axios.post(
            base+'/wf-api/ep/random-event',
            params
        );
    },

  saveBracket(params) {
        return axios.post(
          base+'/api/save-bracket',
            params
        );
    },

  sePointsListSp(params) {
        return axios.post(
          base+'/api/save-points-list-category',
            params
        );
    },

  setPositionListSp(params) {
        return axios.post(
          base+'/api/save-position-list-sp',
            params
        );
    },

  updateBracketFight(params) {
        return axios.post(
          base+'/api/update-bracket-fight',
            params
        );
    },

  autoSortableBracket(params) {
        return axios.post(
          base+'/api/auto-sortable-bracket',
            params
        );
    },

    autoSortableBracketAll(params) {
        return axios.post(
          base+'/api/auto-sortable-bracket-all',
            params
        );
    },

    clearBracket(params) {
        return axios.post(
          base+'/api/clear-bracket',
            params
        );
    },

  removeFromBracketSportsman(params) {
        return axios.post(
          base+'/api/remove-from-bracket-sportsman',
            params
        );
    },

  createCategoryListFirst(params) {
        return axios.post(
          base+'/api/create-category-list-first',
            params
        );
    },


  setCategoryDays(params) {
        return axios.post(
          base+'/api/set-category-days',
            params
        );
    },
  update(params) {
        return axios.post(
          base+'/api/tournament-update',
            params
        );
    },
    create(params) {
      console.log(params);
        return axios.post(
          base+'/wf-api/tournament/create',
          params
        );
    },
  createSection(params) {
      console.log(params);
        return axios.post(
          base+'/api/save-section',
          params
        );
    },
  setCategorySection(params) {
      console.log(params);
        return axios.post(
          base+'/api/save-category-section',
          params
        );
    },

  fightNumeration(params) {
      console.log(params);
        return axios.post(
          base+'/api/category-section-numeration',
          params
        );
    },
  sortableCategoryListSave(params) {
      console.log(params);
        return axios.post(
          base+'/api/sortable-category-list-save',
          params
        );
    },
  createSportsman(params) {
      console.log(params);
        return axios.post(
          base+'/api/tournament-newsportsman',
          params
        );
    },
  createCategory(params) {
      console.log(params);
        return axios.post(
          base+'/api/tournament-newcategory',
          params
        );
    },
  delete(params) {
        return axios.post(
          base+'/api/tournament-delete',
            {
                id: params.id,
            }
        );
    },

  deleteTournamentAllSportsmans(params) {
        return axios.post(
          base+'/api/tournament-delete-all-sportsmans',
            {
                id: params.id,
            }
        );
    },

  deleteOneSportsman(params) {
        return axios.post(
          base+'/api/tournament-delete-one-sportsman',
            {
                id: params.id,
            }
        );
    },

  deleteOneCategory(params) {
        return axios.post(
          base+'/api/tournament-delete-one-category',
            {
                id: params.id,
            }
        );
    },

    getAll() {
        return axios.get(base+'/wf-api/tournament/list');
    },

  getAllSportsmans() {
        return axios.get(base+'/api/tournament-sportsmans');
    },

    getOne(params) {
        return axios.get(base+'/api/tournament',{params:{ id: params.id }});
    },

  getCategoryOne(params) {
        return axios.post(base+'/api/get-category-one',params);
    },

  getOneFull(params) {
        return axios.get(base+'/api/tournament-full',{params:{ id: params.id }});
    },

  getDataSync(params) {
        return axios.get(base+'/api/tournament-data-sync',{params:{ id: params.id }});
    },

  verifyOneSportsman(params) {
        return axios.get(base+'/api/tournament-verify-sportsman',{params:{ id: params.id, verification_status:params.verification_status }});
    },

  uploadFile(formData) {
    return axios.post( base+'/api/tournament-upload-scv-sportsmans',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    },
}
